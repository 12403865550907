export class Store<T> {
    initialState: T;

    constructor(private state: new () => T) {
        this.initialState = new state();
    }

    getState(): T {
        return this.initialState;
    }

    getByKey(key: any): any {
        return this.initialState[key];
    }

    setState(payload: T): void {
        this.initialState = payload;
    }

    set(key: any, payload: any): void {
        if (this.initialState.hasOwnProperty(key)) this.initialState[key] = payload;
    }

    resetState(): void {
        this.initialState = new this.state();
    }
}