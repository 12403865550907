import { Injectable } from "@angular/core";
import { AppSettings } from "src/appSettings";
import { Observable } from "rxjs";
import { Deal } from "src/app/model/deal.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, retry } from "rxjs/operators";
import {
  DealListViewModel,
  DealPaginationModel,
  DealWonTotal,
} from "src/app/dashboard/deal/deal-list/deal-list.component";

@Injectable({
  providedIn: "root",
})
export class DealService {
  constructor(private http: HttpClient) {}

  getDealById(id: number) {
    return this.http.get<Deal>(`/api/deal/${id}`);
  }

  getDeals(searchPhrase) {
    return this.http.get<Deal[]>(`/api/deal/getDeals`);
  }

  getDealForAssignment() {
    return this.http.get<any>("/api/deal/for-asignment");
  }

  assignDeal(request: any): any {
    return this.http.post("/api/deal/assign-deal", request);
  }

  reserveUnit(request: any) {
    return this.http.post("/api/deal/reservation", request);
  }

  releaseDeal(dealId: number) {
    return this.http.post("/api/deal/release-deal", dealId);
  }

  unReserveUnit(userId: any, uid: any) {
    return this.http.delete(
      `/api/deal/reservation?userId=${userId}&uid=${uid}`
    );
  }

  getCurrentStageByDealId(id: number) {
    return this.http.get<number>(`/api/deal/currentStage/${id}`);
  }

  getMyDeals(clientId: string) {
    return this.http.get<Deal[]>(`/api/deal/client/${clientId}`);
  }

  saveDeal(deal: Deal): Observable<any> {
    return this.http.post<Deal>("/api/deal/insertDeal", deal);
  }

  editDeal(deal: Deal): Observable<any> {
    return this.http.post<Deal>("/api/deal/editDeal", deal);
  }

  deleteDeal(id: number) {
    return this.http.post("/api/deal/delete", id);
  }

  exportDeals() {
    return this.http.get("/api/deals/get-for-export", {
      headers: new HttpHeaders({
        disableloader: "true",
      }),
    });
  }

  getPhases() {
    return this.http.get<any[]>("/api/phases", {
      headers: new HttpHeaders({
        disableloader: "true",
      }),
    });
  }

  getDealsPhase(page: number = 1, phase: number = 1) {
    return this.http.get<any>(
      `/api/deals/closed-won?phase=${phase}&page=${page}`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  searchDeals(phase: number = 1, searchKey: string = "") {
    return this.http.get<any>(
      `/api/deals/closed-won?phase=${phase}&key=${encodeURIComponent(
        searchKey
      )}`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  getDealsByStage() {
    return this.http.get<DealListViewModel[]>(`/api/stages/getDealsByStage`);
  }

  getDealsPhaseOne(page: number) {
    return this.http.get<DealPaginationModel>(
      `/api/deals?page=${page}&IsSecondPhase=false`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  getDealsPhaseTwo(page: number) {
    return this.http.get<DealPaginationModel>(
      `/api/deals?page=${page}&IsSecondPhase=true`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  getDealsClosedLost(page: number) {
    return this.http.get<DealPaginationModel>(
      `/api/deals/closed-lost?page=${page}`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  getSearchClosedLost(key: string) {
    return this.http.get<DealPaginationModel>(
      `/api/deals/closed-lost?key=${key}`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  getDealWonTotal() {
    return this.http.get<DealWonTotal>("/api/deals/won/total");
  }

  getDealSearch(isSecondPhase: boolean, key: string) {
    return this.http.get<DealPaginationModel>(
      `/api/deals?IsSecondPhase=${isSecondPhase}&key=${key}`,
      {
        headers: new HttpHeaders({
          disableloader: "true",
        }),
      }
    );
  }

  dealsInStage(id): any {
    return this.http.get<any>(`/api/stages/GetDealsByStage/` + id);
  }

  saveDealFile(formData, id) {
    return this.http.post<any>(`/api/deal/${id}/file`, formData, {
      reportProgress: true,
      observe: "events",
      headers: new HttpHeaders({
        disableloader: "true",
      }),
    });
  }

  deleteDealFile(id): any {
    return this.http.delete<any>(`/api/deal/${id}/file`);
  }

  getDealFiles(dealId): any {
    return this.http.get<any>(`/api/deal/${dealId}/files`);
  }

  downloadDealFiles(dealId): any {
    const options: any = {
      headers: new HttpHeaders({ disableloader: "true" }),
      responseType: "arraybuffer",
    };

    return this.http.get<any>(`/api/deal/${dealId}/downloadfiles`, options);
  }

  downloadDealFile(fileUrl) {
    const options: any = {
      headers: new HttpHeaders({
        disableloader: "true",
        ignoreBaseUrl: "true",
      }),
      responseType: "blob",
    };

    return this.http.get<any>(fileUrl, options);
  }
}
