import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(private commonService:CommonService) {
    this.commonService.invokeEvent.subscribe(value=>{
      this.sidebarFunc();
    })
   }

  ngOnInit() {
  }

  sidebarFunc(){
    
  }
}
