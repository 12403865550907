import { Injectable } from "@angular/core";
import { Store } from "./store";
import { MapState } from "./map.state";
import { MapModel } from "../model/map.model";

@Injectable()
export class MapStore extends Store<MapState> {
  constructor() {
    super(MapState);
  }

  addNewAllocatedUnitToStore(result: any) {
    let allocatedUnitsStored = this.initialState.allocatedUnits;
    let mapModel = new MapModel();
    mapModel.DealId = result.DealId;
    mapModel.Uid = result.Uid;
    mapModel.OwnerFullName = result.OwnerFullName;
    allocatedUnitsStored.push(mapModel);
    this.initialState.allocatedUnits = allocatedUnitsStored;
  }

  resetDealsState() {
    this.initialState.storedClientDeals = [];
  }

  addNewReservedUnitToStore(result: any) {
    let reservedUnitsStored = this.initialState.reservedUnits;
    let mapModel = new MapModel();
    mapModel.UserId = result.UserId;
    mapModel.Uid = result.Uid;
    mapModel.OwnerFullName = result.OwnerFullName;
    reservedUnitsStored.push(mapModel);
    this.initialState.reservedUnits = reservedUnitsStored;
  }

  removeReleasedUnitFromStore(result: any) {
    let allocatedUnitsStored = this.initialState.allocatedUnits;
    let newAllocatedUnitsStore = allocatedUnitsStored.filter(
      x => x.Uid !== result.Uid
    );
    this.initialState.allocatedUnits = newAllocatedUnitsStore;
  }

  removeUnReservedUnitFromStore(result: any) {
    let reservedUnitsStored = this.initialState.reservedUnits;
    let newReservedUnitsStore = reservedUnitsStored.filter(
      x => x.Uid !== result.Uid
    );
    this.initialState.reservedUnits = newReservedUnitsStore;
  }

  updateFlatOrTaracierMapStatsOnAssignment(unitName: string, addedValue = 1) {
    let currentMapStats = this.initialState.mapStats.filter(
      x => x.unitName !== unitName
    );
    let currentUnit = this.initialState.mapStats.filter(
      x => x.unitName === unitName
    )[0];
    if (currentUnit) {
      currentUnit.assignedUnits = currentUnit.assignedUnits + addedValue;
      currentUnit.freeUnits = currentUnit.freeUnits - addedValue;
      currentUnit.freeUnitsInPercentage = this.calculatePercentage(
        currentUnit.freeUnits,
        currentUnit.numberOfApartments
      );
      currentUnit.assignedUnitsInPercentage = this.calculatePercentage(
        currentUnit.assignedUnits,
        currentUnit.numberOfApartments
      );
      currentUnit.reservedUnitsInPercentage = this.calculatePercentage(
        currentUnit.reservedUnits,
        currentUnit.numberOfApartments
      );
      currentMapStats.push(currentUnit);
      this.initialState.mapStats = currentMapStats;
      console.log(
        "%c%s",
        `color: white; background: ${
          addedValue > 0 ? "green" : "red"
        }; font-size: 16px;`,
        `Stats of ${unitName} updated with ${addedValue} on assigned units`
      );
    }
  }

  updateFlatOrTaracierMapStatsOnReservation(unitName: string, addedValue = 1) {
    let currentMapStats = this.initialState.mapStats.filter(
      x => x.unitName !== unitName
    );
    let currentUnit = this.initialState.mapStats.filter(
      x => x.unitName === unitName
    )[0];
    if (currentUnit) {
      currentUnit.reservedUnits = currentUnit.reservedUnits + addedValue;
      currentUnit.freeUnits = currentUnit.freeUnits - addedValue;
      currentUnit.freeUnitsInPercentage = this.calculatePercentage(
        currentUnit.freeUnits,
        currentUnit.numberOfApartments
      );
      currentUnit.reservedUnitsInPercentage = this.calculatePercentage(
        currentUnit.reservedUnits,
        currentUnit.numberOfApartments
      );
      currentUnit.assignedUnitsInPercentage = this.calculatePercentage(
        currentUnit.assignedUnits,
        currentUnit.numberOfApartments
      );
      currentMapStats.push(currentUnit);
      this.initialState.mapStats = currentMapStats;
      console.log(
        "%c%s",
        `color: white; background: ${
          addedValue > 0 ? "green" : "red"
        }; font-size: 16px;`,
        `Stats of ${unitName} updated with ${addedValue} on reserved units`
      );
    }
  }

  calculatePercentage(value1: number, value2: number): string {
    return ((value1 / value2) * 100).toFixed(2);
  }
}
