import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
    private router: Router) { }

  getUserList(): Observable<User[]> {
    return this.http.get<User[]>("/api/auth/users");
  }

  getClientList(): Observable<any> {
    var users = this.http.get<any>('/api/auth/clients');
    return users;
  }


  userCreate(user: User): Observable<any> {
    return this.http.post('/api/auth/register', user)
  }

  delete(user: User): Observable<any> {
    return this.http.post('/api/auth/deleteUser', user);
  }

}
