import { MapModel } from '../model/map.model';
import { MapStats } from '../model/view-models/map-stats.model';

export class MapState {
    allocatedUnits: MapModel[];
    reservedUnits:MapModel[];
    storedClients: any[];
    storedClientDeals:any[];
    mapStats:MapStats[];
    currentMapStatsUid:string;
    constructor() {
        this.allocatedUnits = [];
        this.reservedUnits = [];
        this.storedClients = [];
        this.storedClientDeals = [];
        this.mapStats = [];
        this.currentMapStatsUid = '';
    }
}