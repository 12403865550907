import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-user-sidebar',
  templateUrl: './user-sidebar.component.html',
  styleUrls: ['./user-sidebar.component.scss']
})
export class UserSidebarComponent implements OnInit {

  constructor(private authService: AuthService) { }
  userData:string;
  role:string;
  ngOnInit() {
    let unique_name = localStorage.getItem('unique_name');
    this.role = localStorage.getItem('role');
    this.getUser(unique_name);
  }

  getUser(id) {
    this.authService.getUserById(id).subscribe(response => {
      //console.log(response);
      // this.userData = response;
      this.userData = response['firstName']+" "+response['lastName'];
    })
  }
}
