import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PipeLine } from 'src/app/model/deal.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PipelineService } from 'src/app/service/pipeline.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-pipeline-notes-modal',
  templateUrl: './pipeline-notes-modal.component.html',
  styleUrls: ['./pipeline-notes-modal.component.scss']
})
export class PipelineNotesModalComponent implements OnInit {
  @ViewChild('content') content: any;
  @ViewChild('stepper') stepper: MatStepper;

  Pipeline : PipeLine;
  modalNoteFormGroup: FormGroup;
  constructor(private modalService: NgbModal,private formBuilder: FormBuilder,
              private pipeLineService:PipelineService,private commonService: CommonService) { }

  ngOnInit() {
    this.modalNoteFormGroup = this.formBuilder.group({
      Note: [''],
    });
  }

  open(pipeline:PipeLine) {
    this.modalService.open(this.content, { ariaLabelledBy: 'modal-basic-title', size: 'lg',centered:true});
    this.Pipeline = pipeline;
  }

  savePipelineChanges(){
    this.Pipeline.Note = this.modalNoteFormGroup.value['Note'];
    var datta = this.pipeLineService.createPipeLine(this.Pipeline);
    this.modalService.dismissAll();
    this.commonService.callMethodOfSecondComponent();
  }

}
