import { Component, OnInit, Inject, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { DealService } from "src/app/service/deal/deal.service";
import { MapStore } from "src/app/store/map.store";
import { UserService } from "src/app/service/user/user.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { MapService } from "src/app/service/map.service";

@Component({
  selector: "app-user-modal",
  templateUrl: "./user-modal.component.html",
  styleUrls: ["./user-modal.component.scss"]
})
export class UserModalComponent implements OnInit {
  showReleaseButton: boolean;
  showUnReserveButton: boolean;
  clients: any = [];
  deals: any = [];
  selectedClient: string;
  selectedDeal: any;
  dealId: any;
  userId: any;
  dealUid: any;
  isLoading: boolean = true;
  selectedIndexOfTabs: number = 0;
  clientCtrl = new FormControl();
  dealCtrl = new FormControl();
  filteredClients: Observable<any[]>;
  filteredDeals: Observable<any[]>;

  constructor(
    private _dealService: DealService,
    private _mapStore: MapStore,
    private _userServise: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserModalComponent>
  ) {
    {
      this.userId = data.userId;
      this.dealId = data.dealId;
      this.dealUid = data.uId;
    }
  }

  ngOnInit() {
    this.getDealList();
    this.getClientList();
  }

  getDealList() {
    let storedClientDeals = this._mapStore.getByKey("storedClientDeals");
    if (storedClientDeals.length === 0) {
      this._dealService.getDealForAssignment().subscribe(res => {
        this.isLoading = false;
        this.deals = res;
        this._mapStore.set("storedClientDeals", res);
        this.initFilterDeals();
        if (this.dealId) this.switchToReleaseMode();
      });
    } else {
      this.isLoading = false;
      this.deals = storedClientDeals;
      this.initFilterDeals();
      if (this.dealId) this.switchToReleaseMode();
    }
  }

  getClientList() {
    let storedClients = this._mapStore.getByKey("storedClients");
    if (storedClients.length === 0) {
      this._userServise.getClientList().subscribe(res => {
        this.isLoading = false;
        this.clients = res.data;
        this._mapStore.set("storedClients", res.data);
        this.initFilterClients();
        if (this.userId) this.switchToUnReserveMode();
      });
    } else {
      this.isLoading = false;
      this.clients = storedClients;
      this.initFilterClients();
      if (this.userId) this.switchToUnReserveMode();
    }
  }

  switchToReleaseMode() {
    this.selectedIndexOfTabs = 0;
    this.selectedDeal = parseInt(this.dealId);
    let deal = this.deals.find(x => x.id === this.selectedDeal);
    let value = `${deal.fullName} --- ${deal.dealTitle}`;
    this.dealCtrl.setValue(value);
    this.showReleaseButton = true;
    this.dealCtrl.disable();
  }

  switchToUnReserveMode() {
    this.selectedIndexOfTabs = 1;
    this.selectedClient = this.userId;
    let client = this.clients.find(x => x.id === this.userId);
    let value = `${client.firstName} ${client.lastName}`;
    this.clientCtrl.setValue(value);
    this.showUnReserveButton = true;
    this.clientCtrl.disable();
  }

  initFilterClients() {
    this.filteredClients = this.clientCtrl.valueChanges.pipe(
      startWith(""),
      map(state => (state ? this._filterClients(state) : this.clients.slice()))
    );
  }

  initFilterDeals() {
    this.filteredDeals = this.dealCtrl.valueChanges.pipe(
      startWith(""),
      map(state => (state ? this._filterDeals(state) : this.deals.slice()))
    );
  }

  displayDealLabel(deal: any) {
    return `${deal.fullName} --- ${deal.dealTitle}`;
  }

  displayClientLabel(client: any) {
    return `${client.firstName} ${client.lastName}`;
  }

  private _filterClients(value: string): void {
    const filterValue = value.toLowerCase().replace(/ /g, "");
    return this.clients.filter(state =>
      `${state.firstName} ${state.lastName}`
        .replace(/ /g, "")
        .toLowerCase()
        .includes(filterValue)
    );
  }

  private _filterDeals(value: string): void {
    const filterValue = value.toLowerCase().replace(/ /g, "");
    return this.deals.filter(state =>
      `${state.fullName} --- ${state.dealTitle}`
        .replace(/ /g, "")
        .toLowerCase()
        .includes(filterValue)
    );
  }

  setSelectedClient(value: any) {
    this.selectedClient = value;
  }

  setSelectedDeal(value: any) {
    this.selectedDeal = value;
  }

  onSave() {
    var response = new AssignReserveModel();
    this._dealService
      .assignDeal({ dealId: this.selectedDeal, uId: this.data.uId })
      .subscribe(
        res => {
          response.AssignedSucceeded = true;
          response.Uid = this.data.uId;
          response.DealId = this.selectedDeal;
          response.OwnerFullName = res.fullName;
          response.DealTitle = res.dealTitle;
          this.closeModal(response);
          this.updateMapStatsStoreOnAssigned();
        },
        err => {
          console.log(err);
          this.closeModal(response);
        }
      );
  }

  onReserve() {
    var response = new AssignReserveModel();
    this._dealService
      .reserveUnit({ userId: this.selectedClient, uId: this.data.uId })
      .subscribe(
        res => {
          let client = this.clients.find(x => x.id === this.selectedClient);
          response.ReservedSucceeded = true;
          response.Uid = this.data.uId;
          response.UserId = this.selectedClient;
          response.OwnerFullName = `${client.firstName} ${client.lastName}`;
          this.closeModal(response);
          this.updateMapStatsStoreOnReserved();
        },
        err => {
          console.log(err);
          this.closeModal(response);
        }
      );
  }

  onRelease() {
    var response = new AssignReserveModel();
    this._dealService.releaseDeal(this.selectedDeal).subscribe(
      res => {
        response.ReleasedSucceeded = true;
        response.Uid = this.data.uId;
        this.closeModal(response);
        this.updateMapStatStoreOnRelease();
      },
      err => {
        console.log(err);
        this.closeModal(response);
      }
    );
  }

  onUnReserve() {
    var response = new AssignReserveModel();
    this._dealService
      .unReserveUnit(this.selectedClient, this.data.uId)
      .subscribe(
        res => {
          response.UnReservedSucceeded = true;
          response.Uid = this.data.uId;
          this.closeModal(response);
          this.updateMapStatStoreOnUnReserved();
        },
        err => {
          console.log(err);
          this.closeModal(response);
        }
      );
  }

  updateMapStatsStoreOnAssigned() {
    this._mapStore.set("storedClientDeals", this.deals);
    let allocatedUnits = this._mapStore.getByKey("allocatedUnits");
    this.updateMapStatsForFlatOrTaracierOnAssignmentProcess(1);
    //check for re-assignment;
    let isDealAlreadyAssigned = allocatedUnits.some(
      x => x.DealId === this.selectedDeal
    );
    if (isDealAlreadyAssigned) this.handleReassignment(allocatedUnits);
  }

  updateMapStatStoreOnRelease() {
    this._mapStore.set("storedClientDeals", []);
    this.updateMapStatsForFlatOrTaracierOnAssignmentProcess(-1);
  }

  updateMapStatsStoreOnReserved() {
    this._mapStore.set("storedClients", this.clients);
    this.updateMapStatsForFlatOrTaracierOnReservationProcess(1);
  }

  updateMapStatStoreOnUnReserved() {
    this._mapStore.set("storedClients", []);
    this.updateMapStatsForFlatOrTaracierOnReservationProcess(-1);
  }

  updateMapStatsForFlatOrTaracierOnAssignmentProcess(addedValue) {
    let unitName = this._mapStore.getByKey("currentMapStatsUid"); //check if right-clicked unit comes from FLAT or TARACIER
    if (unitName !== "") {
      if (this.dealUid.includes(unitName)) {
        //check if right-clicked unit is actually FLAT or TARACIER if so update hover stats
        this._mapStore.updateFlatOrTaracierMapStatsOnAssignment(
          unitName,
          addedValue
        );
      }
    }
  }

  updateMapStatsForFlatOrTaracierOnReservationProcess(addedValue) {
    let unitName = this._mapStore.getByKey("currentMapStatsUid"); //check if right-clicked unit comes from FLAT or TARACIER
    if (unitName !== "") {
      if (this.dealUid.includes(unitName)) {
        //check if right-clicked unit is actually FLAT or TARACIER if so update hover stats
        this._mapStore.updateFlatOrTaracierMapStatsOnReservation(
          unitName,
          addedValue
        );
      }
    }
  }

  handleReassignment(allocatedUnits: any[]) {
    let unitName = "";
    let existingAllocatedUnit = allocatedUnits.find(
      x => x.DealId === this.selectedDeal
    );
    let uid = existingAllocatedUnit.Uid;
    existingAllocatedUnit.Uid = this.dealUid;
    this._mapStore.set("allocatedUnits", allocatedUnits);
    if (uid.includes("B") || uid.includes("T")) {
      unitName = uid.substring(0, 2);
      this._mapStore.updateFlatOrTaracierMapStatsOnAssignment(unitName, -1);
    }
    console.log(
      "%c%s",
      "color: white; background:orange; font-size: 16px",
      `Re-assignment from ${uid} to ${this.dealUid} was made successfully`
    );
    this.removePaintOfUnitFromMap(uid);
  }

  removePaintOfUnitFromMap(uid: any) {
    let elements = document.querySelectorAll(`[id="${uid}"]`);
    elements.forEach(el => {
      let element = el as HTMLElement;
      element.removeAttribute("data-title");
      element.removeAttribute("data-dealId");
      element.removeEventListener(
        "mouseover",
        event => {
          event.preventDefault();
        },
        false
      );
      element.removeEventListener(
        "mouseout",
        event => {
          event.preventDefault();
        },
        false
      );

      if (uid.includes("flat") || uid.includes("taracier")) {
        element.firstElementChild.removeAttribute("data-title");
        element.classList.remove("redBackground");
        element.style.backgroundColor = "#00b894 !important";
      } else {
        element.style.fill = "#00b894";
      }
    });
  }

  closeModal(response) {
    this.dialogRef.close(response);
  }
}

export class AssignReserveModel {
  constructor() {
    this.AssignedSucceeded = false;
    this.ReleasedSucceeded = false;
    this.ReservedSucceeded = false;
    this.UnReservedSucceeded = false;
    this.DealId = null;
    this.UserId = null;
    this.Uid = null;
    this.OwnerFullName = null;
    this.DealTitle = null;
  }
  AssignedSucceeded: boolean;
  ReleasedSucceeded: boolean;
  ReservedSucceeded: boolean;
  UnReservedSucceeded: boolean;
  Uid: any;
  DealId: any;
  UserId: any;
  OwnerFullName: any;
  DealTitle: any;
}
