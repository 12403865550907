import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { HeaderComponent } from "./structure/header/header.component";
import { HeaderLogoComponent } from "./structure/header/header-logo/header-logo.component";
import { HeaderToggleComponent } from "./structure/header/header-toggle/header-toggle.component";
import { NotificationHeaderComponent } from "./structure/header/notification-header/notification-header.component";
import { ProfileHeaderComponent } from "./structure/header/profile-header/profile-header.component";
import { SidebarComponent } from "./structure/sidebar/sidebar.component";
import { MenuComponent } from "./structure/sidebar/menu/menu.component";
import { UserSidebarComponent } from "./structure/sidebar/user-sidebar/user-sidebar.component";
import { DashboardComponent } from "./structure/structure.component";

import { NgMaterialModule } from "../ng-material/ng-material.module";
import { NgSelectModule } from "@ng-select/ng-select";

import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PipelineNotesModalComponent } from "./modal/pipeline-notes-modal/pipeline-notes-modal.component";
import { TemplatemodalComponent } from "./modal/templatemodal/templatemodal.component";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderLogoComponent,
    HeaderToggleComponent,
    NotificationHeaderComponent,
    ProfileHeaderComponent,
    SidebarComponent,
    MenuComponent,
    UserSidebarComponent,
    PipelineNotesModalComponent,
    TemplatemodalComponent
  ],
  exports: [
    HeaderComponent,
    HeaderLogoComponent,
    HeaderToggleComponent,
    NotificationHeaderComponent,
    ProfileHeaderComponent,
    SidebarComponent,
    MenuComponent,
    UserSidebarComponent,
    PipelineNotesModalComponent,
    TemplatemodalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgMaterialModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule {}
