import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { LoaderService } from "./loader.service";
import { AuthService } from "./auth.service";
@Injectable()
export class APIInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  public isLoading = new BehaviorSubject(false);

  constructor(private router: Router, private loaderService: LoaderService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // localStorage.setItem('user_token','test'); //testing
    if(!request.url.includes('api/map/stats') && request.headers.get('disableloader') != 'true')
      this.showLoader();

    var userToken = localStorage.getItem("user_token");

    request = request.clone({
      url: environment.apiUrl + `${request.url}`
    });

    if (userToken !== null) {
      //Add users
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken}`
        }
      });
    } else {
      this.router.navigateByUrl("/login");
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        (err: any) => {
          this.handleAuthError(err);
          this.onEnd();
        }
      )
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      //navigate /delete cookies or whatever
      this.router.navigateByUrl(`/access-denied`);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return throwError(err);
    }
    return throwError(err);
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }
}
