import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { SidebarService } from "src/app/service/sidebar.service";
import { CommonService } from 'src/app/service/common.service';

// export interface MenuItem {
//   title: string;
//   links: { title: string, link: string }[]
// }

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
  isHidenDashboard: boolean = false;
  isHidenProducts: boolean = false;
  isHidenDeals: boolean = false;
  showMenu = "";
  showSubMenu = "";
  myRole:string;
  blurify:boolean=false;
  @ViewChild("headerToggle") headerToggle: any;

  public sidebarnavItems: any[];
  public childrenItems: any[];

  constructor(private commonService:CommonService) {
    this.commonService.invokeBlurifyEvent.subscribe(isBlur => {
      this.blurify = isBlur;
    })
  }

  showDashboard() {
    this.isHidenDashboard = !this.isHidenDashboard;
    this.isHidenProducts = false;
    this.isHidenDeals = false;
  }
  showProduct() {
    this.isHidenProducts = !this.isHidenProducts;
    this.isHidenDeals = false;
    this.isHidenDashboard = false;
  }
  showDeals() {
    this.isHidenDeals = !this.isHidenDeals;
    this.isHidenDashboard = false;
    this.isHidenProducts = false;
  }

  toggle() {
    this.headerToggle;
    console.log(this.headerToggle);
  }

  ngOnInit() {
    this.myRole = localStorage.getItem("role");
  }
}
