export class MapUnit {
    Uid: string;
    Type: string;
}

export class MapModel {
    DealId: number;
    UserId: string;
    Uid: string;
    OwnerFullName: string;
}

export class MapStatsModel {
    numberOfAssignedUnits:number;
    numberOfReservedUnits:number;
}