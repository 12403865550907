import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { Router } from "@angular/router";
import { LoaderState } from "src/app/model/loader";
import { LoaderService } from "src/app/service/loader.service";
import { CommonService } from "src/app/service/common.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./structure.component.html",
  styleUrls: ["./structure.component.scss"]
})
export class DashboardComponent implements OnInit {
  isToggle: boolean = false;
  show:boolean = false;
  private subscription: Subscription;

  constructor(private loaderService: LoaderService,private commonService: CommonService,private router: Router) {
    this.commonService.invokeToggleEvenet.subscribe(value => {
      this.toggle();
    });
  }
 
  toggle() {
    this.isToggle = !this.isToggle;
  }

  isUserLoggedIn() {
    return localStorage.getItem("user_token") ? true : false;
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.show = state.show;
    });
    // this.router.navigate(['/deal/list']) //nagivation url will be changed to statistics url

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
