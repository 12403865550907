import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  blurify:boolean=false;
  constructor(private commonService: CommonService) {
    this.commonService.invokeBlurifyEvent.subscribe(isBlur => {
      this.blurify = isBlur;
    })
   }

  ngOnInit() {
  }

  alert() {
    this.commonService.callToggleEvent();
  }

}
