import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RouterLink, Router } from "@angular/router";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { User } from "../model/user.model";
import { AppSettings } from "src/appSettings";
import { CommonService } from "./common.service";

import * as jwt_decode from "jwt-decode";
import { NotifyAlert } from "../shared/notify/notify.alert";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  // public isLoading = new BehaviorSubject(false);
  constructor(
    private http: HttpClient,
    private router: Router,
    private commonService: CommonService,
    private alert: NotifyAlert
  ) {
    this.commonService.invokeUserExist.subscribe(value => {
      this.userExist();
    });
  }
  user: any = null;
  token;

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  authUser(loginForm: any) {
    this.http
      .post("/api/auth/authenticate", loginForm, {
        responseType: "text"
      })
      .subscribe(data => {
        if (data != "authentication failed") {
          localStorage.setItem("user_token", data.toString());
          var userToken = localStorage.getItem("user_token");
          let tokenInfo = this.getDecodedAccessToken(userToken);
          localStorage.setItem("unique_name", tokenInfo.unique_name);
          this.setRole(data.toString());

          if (localStorage.getItem('role') == 'Admin') {
            this.router.navigateByUrl("deal");
          }
          else if (localStorage.getItem('role') == 'Staff') {
            this.router.navigateByUrl("/client");
          } else {
            this.logout();
          }
        } else {
          this.alert.error("Username or Password is incorrect", "X");
          this.router.navigateByUrl("/login");
        }
      });
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user_token");
    localStorage.removeItem("role");
    localStorage.removeItem("unique_name");
    this.router.navigateByUrl("/login");
  }

  registerUser(RegisterModel) {
    return this.http
      .post("/api/auth/register", RegisterModel, {
        responseType: "json"
      })
      .subscribe(data => {
        this.user = data;
        if (data) {
          let registerUserRole = `${RegisterModel.roleName === 'Client' ? 'Client' : 'User'}`;
          this.alert.success(`${registerUserRole} registered successfully`, "");
          this.router.navigateByUrl(`/${registerUserRole.toLowerCase()}`);
        } else {
          this.alert.error(`Error on user registration`, "");
        }
      }, err => {
        this.alert.error(`Error on user registration`, "");
        console.log(err);
      });
  }

  updateUser(userId: any, user: User) {
    return this.http.put("/api/auth/update/" + userId, user).subscribe(data => {
      this.user = data;
      if (data) {
        this.alert.success(`User updated successfully`, "");
        this.router.navigateByUrl('/user');
      } else {
        this.alert.error(`Error on updating user`, "");
      }
    }, err => {
      this.alert.error(`Error on updating user`, "");
      console.log(err);
    });
  }

  updateClient(userId: any, user: User) {
    return this.http.put("/api/auth/update/" + userId, user).subscribe(data => {
      this.user = data;
      if (data) {
        this.alert.success(`Client updated successfully`, "");
        this.router.navigateByUrl("/client");
      } else {
        this.alert.error(`Error on updating client`, "");
      }
    }, err => {
      this.alert.error(`Error on updating client`, "");
      console.log(err);
    });
  }

  updateProfile(userId: any, user: User) {
    return this.http.put("/api/auth/update/" + userId, user).subscribe(data => {
      this.user = data;
      if (data) {
        this.alert.success(`Profile updated successfully`, "");
        let basedRoleRedirection = localStorage.getItem('role') === "Staff" ? 'client' : 'in';
        this.router.navigateByUrl(`/${basedRoleRedirection}`);
      } else {
        this.alert.error(`Error on updating profile`, "");
      }
    }, err => {
      this.alert.error(`Error on updating profile`, "");
      console.log(err);
    });
  }

  userExist() {
    this.user;
  }

  setRole(token: string) {
    let jwt = token;
    let jwtData = jwt.split(".")[1];
    let decodedJwtJsonData = window.atob(jwtData);
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    localStorage.setItem("role", decodedJwtData.role);
  }

  getUserById(id): Observable<User> {
    return this.http.get<User>(`/api/auth/user/${id}`);
  }

  get isLoggedIn(): Observable<boolean> {
    var subject = new Subject<boolean>();
    if (localStorage.getItem("user_token")) {
      // logged in so return true
      subject.next(true);
    } else {
      subject.next(false);
    }
    return subject.asObservable();
  }
}
