import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Template } from "../model/template.model";
import { AppSettings } from "src/appSettings";
import { NotifyAlert } from "../shared/notify/notify.alert";
@Injectable({
  providedIn: "root"
})
export class TemplateService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: NotifyAlert
  ) {}

  getTemplates(): Observable<Template[]> {
    return this.http.get<Template[]>("/api/templates");
  }

  createTemplate(TemplateModel) {
    return this.http
      .post("/api/templates/add", TemplateModel, {
        responseType: "json"
      })
      .subscribe(
        data => {
          this.alert.success("Template create with Success", "");
          if (data) {
            return data;
          }
        },
        err => {
          this.alert.error("Error to create Template", "");
        }
      );
  }
}
