import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { MapModel } from "src/app/model/map.model";
import { MapStore } from "src/app/store/map.store";
import { MapService } from "src/app/service/map.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-taracier",
  templateUrl: "./taracier.component.html",
  styleUrls: ["./taracier.component.scss"],
})
export class TaracierComponent implements OnInit, AfterViewInit {
  dynamicId: string;
  typeOfUnit: string;
  indexOfUnit: string;
  allocatedUnits: MapModel[];
  taracierNameMapping = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  taracier1To9Apartments = [
    {
      index: 1,
      taracierSuffix: "1P",
      apartmentInformation: "153.13m2 + 49.8m2",
    },
    {
      index: 2,
      taracierSuffix: "1L",
      apartmentInformation: "201.55m2 + 73.91m2",
    },
    {
      index: 3,
      taracierSuffix: "2L",
      apartmentInformation: "153.13m2 + 49.8m2",
    },
    {
      index: 4,
      taracierSuffix: "2P",
      apartmentInformation: "159.8m2 + 49.8m2",
    },
    {
      index: 5,
      taracierSuffix: "3L",
      apartmentInformation: "227.1m2 + 73.91m2",
    },
    {
      index: 6,
      taracierSuffix: "4L",
      apartmentInformation: "159.8m2 + 49.8m2",
    },
    {
      index: 7,
      taracierSuffix: "6L",
      apartmentInformation: "116.39m2 + 49.8m2",
    },
    {
      index: 8,
      taracierSuffix: "5L",
      apartmentInformation: "152.69m2 + 73.91m2",
    },
    {
      index: 9,
      taracierSuffix: "3P",
      apartmentInformation: "116.39m2 + 49.8m2",
    },
    {
      index: 10,
      taracierSuffix: "7L",
      apartmentInformation: "165.06m2 + 96.6m2",
    },
    {
      index: 11,
      taracierSuffix: "4P",
      apartmentInformation: "165.06m2 + 96.6m2",
    },
  ];

  constructor(
    private _mapStore: MapStore,
    private _mapService: MapService,
    public dialogRef: MatDialogRef<TaracierComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data) {
      this.typeOfUnit = data["typeOfUnit"];
      this.indexOfUnit = data["indexOfUnit"];
      this.dynamicId = `${this.typeOfUnit}${this.indexOfUnit}`;
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this._mapStore.set("currentMapStatsUid", this.dynamicId);
    let allocatedUnits = this._mapStore.getByKey("allocatedUnits");
    let reservedUnits = this._mapStore.getByKey("reservedUnits");
    this.disposeMap(allocatedUnits, reservedUnits);
  }

  getTaracierDescription(indexOfApartment) {    
    var taracier = this.taracier1To9Apartments.find(
      (x) => x.index === indexOfApartment
    );
    var mappedName = this.taracierNameMapping[parseInt(this.indexOfUnit) - 1];
    return `${taracier.apartmentInformation} <br><strong class='white-info'>${mappedName}${taracier.taracierSuffix}</strong>`;
  }

  disposeMap(allocatedUnits: MapModel[], reservedUnits: MapModel[]) {
    this._mapService.disposeMap(allocatedUnits, reservedUnits, this.dynamicId);
  }

  onClick(event: any) {
    this.dialogRef.close();
    this._mapService.onUnitClick(event);
  }

  onRightClick(event) {
    let element = event.target;
    event.preventDefault();
    debugger;
    let uid = element.id === "" ? element.closest("div.apt").id : element.id;
    let dealId =
      element.getAttribute("data-dealid") === null
        ? element.closest("div.apt").getAttribute('data-dealid')
        : element.getAttribute("data-dealid");
    let userId =
      element.getAttribute("data-userId") === null
        ? element.parentElement.getAttribute("data-userId")
        : element.getAttribute("data-userId");
    this._mapService.openUserModal(uid, userId, dealId);
  }
}
