import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// ====================== Component ===============================
import { DashboardComponent } from "./structure/structure.component";
import { AuthGuard } from '../service/auth.guard';
import { environment } from 'src/environments/environment';

// ====================== Module ==================================
//import { StatisticModule } from "./statistic/statistic.module";

const routes: Routes = [
  {
    path: "", component: DashboardComponent, canActivate: [AuthGuard], children: [

      // { path: "in", loadChildren: './statistic/statistic.module#StatisticModule' },

      { path: "deal", loadChildren: './deal/deal.module#DealModule' },

      { path: "map", loadChildren: './map/map.module#MapModule' },

      { path: "period", loadChildren: './period/period.module#PeriodModule' },

      { path: "profile", loadChildren: './profile/profile.module#ProfileModule' },

      { path: "period", loadChildren: './period/period.module#PeriodModule' },

      { path: "template", loadChildren: './template/template.module#TemplateModule' },

      { path: "unit", loadChildren: './unit/unit.module#UnitModule' },

      { path: "user", loadChildren: './user/user.module#UserModule' },
      
      { path: "client", loadChildren: './client/client.module#ClientModule' },

      { path: "category", loadChildren: './category/category.module#CategoryModule' },

      { path: "access-denied", loadChildren: './access-denied/access-denied.module#AccessDeniedModule' },

      { path: "**", loadChildren: './not-found/not-found.module#NotFoundModule' },

      { path: "", redirectTo: "deal", pathMatch: "full" }
    ]
  },


]


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
