import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './signin/login.component';
import { NgMaterialModule } from '../ng-material/ng-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SignupComponent } from './signup/signup.component';

@NgModule({
  declarations: [LoginComponent, SignupComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    NgMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class LoginModule { }
