import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthService } from "src/app/service/auth.service";
import { CommonService } from "src/app/service/common.service";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import { LoaderService } from "src/app/service/loader.service";
import { Subscription } from "rxjs";
import { LoaderState } from "src/app/model/loader";

import { MatSnackBar } from "@angular/material";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  show: boolean = false;
  loginForm: FormGroup;
  flip: boolean = false;
  private subscription: Subscription;

  @ViewChild("clearPassword") clearPassword: ElementRef;

  constructor(
    private loaderService: LoaderService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    if (localStorage.getItem("user_token")) this.router.navigateByUrl('/deal');
    else this.router.navigate(["/login"]);
  }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
      }
    );
    this.loginForm = this.formBuilder.group({
      username: "",
      password: "",
      checkbox: false
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loginUser() {
    this.authService.authUser(this.loginForm.value);
    this.clearPassword.nativeElement.value = null;
  }

  forgetPassword() {
    this.flip = !this.flip;
  }
}
