import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from './service/loader.service';
import { Subscription } from 'rxjs';
import { LoaderState } from './model/loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private loaderService: LoaderService) { }

}
