import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DealStore } from 'src/app/store/deal.store';
import { MapStore } from 'src/app/store/map.store';
import { MapModel } from 'src/app/model/map.model';
import { MapService } from 'src/app/service/map.service';
import { UserModalComponent } from '../../user-modal/user-modal.component';

@Component({
  selector: 'app-flat',
  templateUrl: './flat.component.html',
  styleUrls: ['./flat.component.scss']
})
export class FlatComponent implements OnInit, AfterViewInit {
  phFloorSelected: boolean;
  isFlat167: boolean = true;

  dynamicId: string;
  typeOfUnit: string;
  indexOfUnit: string;

  initialFloor: number = 1;
  tempFloorIndex: number = 1;
  selectedIndexFloor: number = 1;
  numberOfApartments: number = 0;
  numberOfFloors: number = 0;

  apartmentsArray: any[];
  floorsArray: any[];
  flat167: number[] = [1, 6, 7];
  flat248: number[] = [2, 4, 8];
  allocatedUnits: MapModel[];
  reservedUnits: MapModel[];

  constructor(
    private _mapStore: MapStore,
    private _mapService: MapService,
    public dialogRef: MatDialogRef<FlatComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data) {
      this.typeOfUnit = data['typeOfUnit'];
      this.indexOfUnit = data['indexOfUnit'];
      this.apartmentsArray = JSON.parse(JSON.stringify(data['apartments']));
      this.numberOfApartments = this.apartmentsArray.length - this.apartmentsArray.filter(x => x.index === -1).length;
      this.numberOfFloors = data['numberOfFloors'];
      this.floorsArray = this.createRange(this.numberOfFloors, true);
      this.dynamicId = `${this.typeOfUnit}${this.indexOfUnit}K${this.initialFloor}`;
      this.isFlat167 = this.flat167.indexOf(parseInt(this.indexOfUnit)) > -1;
    }
  }

  ngOnInit() { }

  ngAfterViewInit() {
    this._mapStore.set('currentMapStatsUid', `${this.typeOfUnit}${this.indexOfUnit}`);
    this.allocatedUnits = this._mapStore.getByKey('allocatedUnits');
    this.reservedUnits = this._mapStore.getByKey('reservedUnits');
    this.disposeMap(this.allocatedUnits, this.reservedUnits);
  }

  disposeMap(allocatedUnits: MapModel[], reservedUnits: MapModel[]) {
    this.reStyleElements('redBackground');
    this.reStyleElements('orangeBackground');
    this._mapService.disposeMap(allocatedUnits, reservedUnits, this.dynamicId);
  }

  onClick(event: any) {
    this.dialogRef.close();
    this._mapService.onUnitClick(event);
  }

  onRightClick(event) {
    let element = event.target;
    event.preventDefault();
    debugger;
    let uid = element.id === "" ? element.closest("div.apt").id : element.id;
    let dealId = element.getAttribute('data-dealid') === null ? element.closest("div.apt").getAttribute('data-dealid') : element.getAttribute('data-dealid');
    let userId = element.getAttribute('data-userId') === null ? element.parentElement.getAttribute('data-userId') : element.getAttribute('data-userId');
    this._mapService.openUserModal(uid, userId, dealId);
  }

  reStyleElements(elementClass) {
    //here we make sure to re-style elements which have been styled on the other floors not current or selected floor
    let attrToRename = elementClass === 'redBackground' ? 'data-dealId' : 'data-userId';
    var els = document.querySelectorAll(`.${elementClass}`)
    for (var i = 0; i < els.length; i++) {
      els[i].classList.remove(elementClass);
      els[i].attributes.removeNamedItem(attrToRename);
      els[i].firstElementChild.attributes.removeNamedItem('data-title');
    }
  }

  changeFloor(event: any) {
    var element = event.target;
    var currentFloor = parseInt(element.getAttribute('id'));
    var numberOfApartments = this.numberOfApartments;
    this.selectedIndexFloor = currentFloor;
    this.phFloorSelected = true;

    if (currentFloor <= this.numberOfFloors) {
      let tempFloorIndex = this.tempFloorIndex;
      if (currentFloor > tempFloorIndex) this.apartmentsArray.forEach((apartment) => { apartment.apartmentNumber = apartment.apartmentNumber + numberOfApartments * (currentFloor - tempFloorIndex) })
      else if (currentFloor < tempFloorIndex) this.apartmentsArray.forEach((apartment) => { apartment.apartmentNumber = apartment.apartmentNumber - numberOfApartments * (tempFloorIndex - currentFloor) })
      else console.log('do nothing');
      this.tempFloorIndex = currentFloor;
      this.dynamicId = `${this.typeOfUnit}${this.indexOfUnit}K${currentFloor}`;
      this.phFloorSelected = false;
    }

    if(this.phFloorSelected && currentFloor > this.numberOfFloors){
      this.dynamicId = `${this.typeOfUnit}${this.indexOfUnit}PH`;
    }
    //re dispose modal-map
    setTimeout(() => {
      this.disposeMap(this.allocatedUnits, this.reservedUnits)
    }, 50);
  }

  createRange(limit: number, reverse: boolean = false) {
    var items: number[] = [];
    for (var i = 1; i <= limit; i++) {
      items.push(i);
    }
    return reverse ? items.sort((a, b) => b - a) : items;
  }
}
