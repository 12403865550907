import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PipeLine, CashFlow, Deal } from '../model/deal.model';
import { Template } from '../model/template.model';
import { User } from '../model/user.model';
import { ForecastedItem } from '../model/forecastedItem.model';
import { ForecastedSaleVM } from '../model/view-models/forecasted-sales/forecastedsalevm';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  invokeEvent: Subject<any> = new Subject();
  invokePipelineNotesEvent:Subject<PipeLine> = new Subject();
  invokeTemplatesEvent :Subject<Template> = new Subject();
  invokeToggleEvenet: Subject<any> = new Subject();
  invokeCashFlowsEvent :Subject<CashFlow> = new Subject();
  invokeUserExist: Subject<User> = new Subject();
  invokePeriodBundleEvent:Subject<any>=new Subject();
  invokeDeleteBundleEvent:Subject<number> = new Subject();
  initEditDeal:Subject<any> = new Subject();
  initCreateDeal:Subject<any> = new Subject();
  invokeCashFlowModalOpener:Subject<any> = new Subject();
  invokeBlurifyEvent:Subject<boolean> = new Subject();
  constructor() { }

  callMethodOfSecondComponent() {
    this.invokeEvent.next();
  }
  
  callGetNotesByDealId(){
    this.invokePipelineNotesEvent.next();
  }

  callGetCashFlows(){
    this.invokeCashFlowsEvent.next();
  }

  callGetTemplates(){
    this.invokeTemplatesEvent.next();
  }

  callToggleEvent() {
    this.invokeToggleEvenet.next();
  }

  callUserExist() {
    this.invokeUserExist.next();
  }

  callBlurifyEvent(isBlur:boolean) {
    this.invokeBlurifyEvent.next(isBlur);
  }

  callPeriodBundle(item:ForecastedSaleVM){
    this.invokePeriodBundleEvent.next(item);
  }
  
  deletePeriodBundle(id:number){
    this.invokeDeleteBundleEvent.next(id);
  }

  editDeal(deal: Deal){
    this.initEditDeal.next(deal);
  }
  createDeal(){
    this.initCreateDeal.next();
  }

  openCashFlowModal(values:any) {
    this.invokeCashFlowModalOpener.next(values);
  }    
}
