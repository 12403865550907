import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PrivacyComponent } from './component/privacy/privacy.component';

const routes: Routes = [
  { path: '', redirectTo: 'deal', pathMatch: 'full' },
  { path: 'privacy', component: PrivacyComponent },
  { path: "login", loadChildren: './login/login.module#LoginModule' }
];


@NgModule({
  // imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
