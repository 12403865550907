import { Injectable } from "@angular/core";
import { AppSettings } from "src/appSettings";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PipeLine } from "../model/deal.model";
import { Router } from "@angular/router";
import { NotifyAlert } from "../shared/notify/notify.alert";
import { CommonService } from './common.service';

@Injectable({
  providedIn: "root"
})
export class PipelineService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: NotifyAlert,
    private commonService:CommonService
  ) {}

  createPipeLine(pipeline: PipeLine) {
    return this.http
      .post("/api/pipeLine/add", pipeline, { responseType: "json" })
      .subscribe(
        data => {
          this.alert.success("Pipeline create with Success", "");
          if (data != null) {
            return data["CurrentStageId"];
          }
        },
        err => this.alert.error("Error to create Pipeline", "")
      );
  }

  updatePipeLine(pipeline: PipeLine) {
    return this.http.put("/api/pipeLine", pipeline).subscribe(
      data => {
        this.alert.success("Pipeline edited with Success", "");
        this.commonService.callGetNotesByDealId();

      },
      err => this.alert.error("Error to edit Pipeline", "")
    );
  }
}
