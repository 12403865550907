import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Unit } from "../model/unit.model";
import { AppSettings } from "src/appSettings";
import { catchError } from "rxjs/operators";
import { NotifyAlert } from "../shared/notify/notify.alert";

@Injectable({
  providedIn: "root"
})
export class UnitService {
  private _addEdit = "";
  constructor(
    private http: HttpClient,
    private router: Router,
    private alert: NotifyAlert
  ) { }

  getUnits(): Observable<Unit[]> {
    return this.http.get<Unit[]>("/api/units/GetAll");
  }

  getUnit(id) {
    return this.http.get<Unit>(`/api/units/get/${id}`);
  }

  getAvailable(): any {
    return this.http.get<Unit[]>("/api/units/AvailableUnits");
  }

  createUpdateUnit(UnitModel: any, initialStock: number, soldUnits: number) {
    if (UnitModel.Id == null) {
      UnitModel.Id = 0;
      UnitModel.OnStock = UnitModel.InitialStock
      return this.http
        .post<Unit>("/api/units/add", UnitModel, {
          responseType: "json"
        })
        .subscribe(
          res => {
            this.alert.success("Unit was created successfully", "");
            this.router.navigateByUrl("/unit");
          },
          err => {
            this.alert.error("Error on creating Unit", "");
          }
        );
    } else {
      UnitModel.InitialStock = parseInt(UnitModel.InitialStock);
      if (UnitModel.InitialStock < soldUnits) {
        this.alert.error("You are trying to decrease stock while they are assigned on existing deals", "");
        return;
      }
      let remainedStock = (UnitModel.InitialStock - initialStock)
      UnitModel.OnStock += remainedStock;
      if (UnitModel.OnStock < 0) UnitModel.OnStock = 0;
      return this.http
        .put<Unit>("/api/units/edit", UnitModel, {
          responseType: "json"
        })
        .subscribe(res => {
          this.alert.success("Unit was edited successfully", "");
          this.router.navigateByUrl("/unit");
        }, err => {
          this.alert.error("Error on editing Unit", "");
        });
    }
  }

  deleteUnit(id) {
    return this.http.delete('/api/units/delete/' + id).subscribe(res => {
      if (res) {
        this.alert.success('Unit deleted successfully', '')
      } else {
        this.alert.error('Unit cannot be deleted because is associated to an existing Deal', "")
      }
      this.router.navigateByUrl("/unit");
    })
  }
}
