import { Component, OnInit } from '@angular/core';
import { UnitService } from 'src/app/service/unit.service';
import { TemplateService } from 'src/app/service/template.service';
import { Template } from 'src/app/model/template.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/service/common.service';


@Component({
  selector: 'app-templatemodal',
  templateUrl: './templatemodal.component.html',
  styleUrls: ['./templatemodal.component.scss']
})
export class TemplatemodalComponent implements OnInit {

  display: boolean = false;
  templateSection = [];
  units = [];
  modalFormGroup:FormGroup;

  constructor(private unitService: UnitService, private templateService: TemplateService,
              private formBuilder: FormBuilder,private commonService: CommonService) { }

  ngOnInit(): void {
    this.getUnits();
    this.modalFormGroup = this.formBuilder.group({
      Description: [''],
    });
  }
  getUnits() {
    this.unitService.getUnits().subscribe(data => {
      this.units = data;
    })
  }

 
  saveTemplate(event) {
    if (this.templateSection.length != 0) {
      const TemplateModel = new Template();
     
      TemplateModel.Id = 0;
      TemplateModel.Description = this.modalFormGroup.value['Description'];
      this.templateSection.forEach(function (value) {
        TemplateModel.UnitIDs.push(value.Id);
      });
      if(this.templateService.createTemplate(TemplateModel)){
        setTimeout(() => {
          this.commonService.callGetTemplates();
        }, 50);
      
      }
      // this.templateService.createTemplate(TemplateModel)
    }
    else
      alert('Select at least one unit');
    // this.templateService.createTemplate(TemplateModel);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    } else {

      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

}
