import { Injectable } from '@angular/core';
import { AppSettings } from 'src/appSettings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MapModel, MapStatsModel, MapUnit } from '../model/map.model';
import { Router } from '@angular/router';
import { DealStore } from '../store/deal.store';
import { MapStore } from '../store/map.store';
import { MatDialog } from '@angular/material';
import { UserModalComponent } from '../dashboard/map/user-modal/user-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(private http: HttpClient,
    private _dealStore: DealStore,
    private _mapStore: MapStore,
    private _router: Router,
    public dialog: MatDialog) { }

  getAllocatedUnits(): Observable<MapModel[]> {
    return this.http.get<MapModel[]>("/api/map/units/allocated");
  }
  
  getUnAllocatedUnits(): Observable<MapUnit[]> {
    return this.http.get<MapUnit[]>("/api/map/units/un-allocated");
  }

  getReservations(): Observable<MapModel[]> {
    return this.http.get<MapModel[]>("/api/deal/reservation");
  }

  getMapStats(search: string): Observable<MapStatsModel> {
    return this.http.get<MapStatsModel>(`/api/map/stats?search=${search}`);
  }

  disposeMap(allocatedUnits: MapModel[], reservedUnits: MapModel[], keyWord: string) {
    allocatedUnits = allocatedUnits.filter(x => x.Uid.includes(keyWord));
    reservedUnits = reservedUnits.filter(x => x.Uid.includes(keyWord));
    allocatedUnits.forEach(item => { this.styleDivElements(0, item as MapModel); });
    reservedUnits.forEach(item => { this.styleDivElements(1, item as MapModel); });
  }

  openUserModal(uid: any, userId: any, dealId: any) {
    const dialogRef = this.dialog.open(UserModalComponent, {
      width: '600px',
      height: '45vh',
      data: {
        userId: userId,
        dealId: dealId,
        uId: uid
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.AssignedSucceeded)
          this.onAssignedSucceeded(result);
        else if (result.ReleasedSucceeded)
          this.onReleasedSucceeded(result)
        else if (result.ReservedSucceeded)
          this.onReservedSucceeded(result);
        else if (result.UnReservedSucceeded)
          this.onUnReservedSucceeded(result);
      }
    });
  }

  onAssignedSucceeded(result, isDivClicked = true) {
    this._mapStore.addNewAllocatedUnitToStore(result);
    this._mapStore.removeUnReservedUnitFromStore(result);
    if (isDivClicked) this.styleDivs(0, result);
    else this.stylePolylines(0, result);
  }

  onReleasedSucceeded(result, isDivClicked = true) {
    this._mapStore.removeReleasedUnitFromStore(result);
    if (isDivClicked) this.removeStyleOfDivUnit(result);
    else this.removeStyleOfSVGUnit(result);
  }

  onReservedSucceeded(result, isDivClicked = true) {
    this._mapStore.addNewReservedUnitToStore(result);
    if (isDivClicked) this.styleDivs(1, result);
    else this.stylePolylines(1, result);
  }

  onUnReservedSucceeded(result, isDivClicked = true) {
    this._mapStore.removeUnReservedUnitFromStore(result);
    if (isDivClicked) this.removeStyleOfDivUnit(result);
    else this.removeStyleOfSVGUnit(result);
  }

  styleDivs(resultType: number, result: MapModel) {
    let elements = document.querySelectorAll(`[id="${result.Uid}"]`);
    for (var i = 0; i < elements.length; i++) this.styleDivElements(resultType, result)
  }

  styleDivElements(resultType: number, item: any) {
    let dataDealOrUserIdValue = resultType == 0 ? item.DealId.toString() : item.UserId.toString();
    let dataDealOrUserIdAttr = resultType == 0 ? 'data-dealId' : 'data-userId';
    let colorToFill = resultType == 0 ? ' redBackground' : ' orangeBackground';
    let element = document.getElementById(`${item.Uid}`);
    if (element) {
      element.firstElementChild.setAttribute('data-title', item.OwnerFullName);
      element.setAttribute(dataDealOrUserIdAttr, dataDealOrUserIdValue);
      element.classList.remove('redBackground');
      element.classList.remove('orangeBackground')
      element.className += colorToFill;
    }
  }

  removeStyleOfDivUnit(result: any) {
    let elements = document.querySelectorAll(`[id="${result.Uid}"]`);
    elements.forEach(el => {
      let element = el as HTMLElement;
      element.removeAttribute('data-title');
      element.removeAttribute('data-dealId');
      element.firstElementChild.removeAttribute('data-title');
      element.classList.remove('redBackground');
      element.classList.remove('orangeBackground')
      element.style.backgroundColor = '#00b894 !important';
      element.removeEventListener('mouseover', (event) => { event.preventDefault(); }, false);
      element.removeEventListener('mouseout', (event) => { event.preventDefault(); }, false);
    });
  }

  stylePolylines(resultType: number, result: MapModel) {
    let dataDealOrUserIdValue = resultType == 0 ? result.DealId.toString() : result.UserId.toString();
    let dataDealOrUserIdAttr = resultType == 0 ? 'data-dealId' : 'data-userId';
    let colorToFill = resultType == 0 ? 'red' : '#f5cd79';
    let elements = document.querySelectorAll(`[id="${result.Uid}"]`);
    let popupTitle = document.getElementById("popupTitle");
    elements.forEach(el => {
      let element = el as HTMLElement;
      element.setAttribute('data-title', result.OwnerFullName);
      element.setAttribute(dataDealOrUserIdAttr, dataDealOrUserIdValue);
      element.style.fill = colorToFill;
      this.addPopUpEventListeners(element, popupTitle);
    });
  }

  removeStyleOfSVGUnit(result: any) {
    let elements = document.querySelectorAll(`[id="${result.Uid}"]`);
    elements.forEach(el => {
      let element = el as HTMLElement;
      element.removeAttribute('data-title');
      element.removeAttribute('data-dealId');
      element.removeAttribute('data-userId');
      element.style.fill = '#00b894';
      element.removeEventListener('mouseover', (event) => { event.preventDefault(); }, false);
      element.removeEventListener('mouseout', (event) => { event.preventDefault(); }, false);
    });
  }

  addPopUpTitleToElements() {
    var elements = document.querySelectorAll('[data-title]');
    let popupTitle = document.getElementById("popupTitle");
    elements.forEach(element => {
      this.addPopUpEventListeners(element, popupTitle);
    })
  }

  addPopUpEventListeners(element, popupTitle) {
    element.addEventListener('mouseover', () => {
      var elementPos = element.getBoundingClientRect();
      popupTitle.firstElementChild.innerHTML = element.getAttribute('data-title');
      popupTitle.style.left = (elementPos.right + 10) + "px";
      popupTitle.style.top = (window.scrollY + elementPos.top - 40) + "px";
      popupTitle.style.display = "block";
    });
    element.addEventListener("mouseout", () => {
      popupTitle.style.display = "none";
    });
  }

  onUnitClick(event: any) {
    if (localStorage.getItem('role') === "Staff")
      return;
    let element = event.target;
    let dealId = element.getAttribute('data-dealId');
    if (!dealId) dealId = element.closest("div.apt").getAttribute('data-dealid');

    if (dealId) {
      this._router.navigateByUrl(`/deal/${dealId}/pipeline`);
    } else {
      let uid = element.getAttribute('id');
      if (!uid) uid = element.parentElement.id

      this._dealStore.setState({ uid: uid, openCreateDealModal: true });
      this._router.navigateByUrl(`/deal`);
    }
  }
}
