import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material";

@Injectable({
  providedIn: "root"
})
export class NotifyAlert {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["style-success"],
      horizontalPosition: "right",
      direction: "ltr"
    });
  }

  error(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["style-error"],
      horizontalPosition: "right",
      direction: "ltr"
    });
  }

  warning(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["style-warning"],
      horizontalPosition: "right",
      direction: "ltr"
    });
  }

  info(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: ["style-info"],
      horizontalPosition: "right",
      direction: "ltr"
    });
  }
}
