import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-flat3',
  templateUrl: './flat3.component.html',
  styleUrls: ['./flat3.component.scss']
})
export class Flat3Component implements OnInit {

  phFloorSelected: boolean;
  floorsArray: any[];
  selectedIndexFloor: number = 1;
  numberOfFloors: number = 0;

  constructor(public dialogRef: MatDialogRef<Flat3Component>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data) {
      this.numberOfFloors = data['numberOfFloors'];
      this.floorsArray = this.createRange(this.numberOfFloors, true);
    }
  }

  ngOnInit() {
  }

  createRange(limit: number, reverse: boolean = false) {
    var items: number[] = [];
    for (var i = 1; i <= limit; i++) {
      items.push(i);
    }
    return reverse ? items.sort((a, b) => b - a) : items;
  }

  changeFloor(event: any) {
    var element = event.target;
    var currentFloor = parseInt(element.getAttribute('id'));
    this.selectedIndexFloor = currentFloor;
    this.phFloorSelected = true;
  }

}
