import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";

import { APIInterceptor } from "./service/apiinterceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthService } from "./service/auth.service";

import { CommonModule } from "@angular/common";

import { TreeviewModule } from "ngx-treeview";
import { ErrorInterceptor } from "./_helpers/error.interceptor";
import { LoaderService } from "./service/loader.service";
import { AuthGuard } from "./service/auth.guard";

import { NgMaterialModule } from "./ng-material/ng-material.module";

import { LoginModule } from "./login/login.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { DashboardComponent } from "./dashboard/structure/structure.component";
import { ChartDoughnutComponent } from "./shared/chart/chart-doughnut/chart-doughnut.component";
import { ChartBarComponent } from './shared/chart/chart-bar/chart-bar.component';
import { TestComponent } from './test/test.component';
import { DashboardRoutingModule } from './dashboard/dashboard-routing.module';
import { PrivacyComponent } from './component/privacy/privacy.component';
import { DealStore } from './store/deal.store';
import { MapStore } from './store/map.store';
import { Flat5Component } from './dashboard/map/components/flat5/flat5.component';
import { Flat3Component } from './dashboard/map/components/flat3/flat3.component';
import { FlatComponent } from './dashboard/map/components/flat/flat.component';
import { Flat3Floor1Component } from './dashboard/map/components/flat3/floors/floor1/floor1.component';
import { Flat3Floor2Component } from './dashboard/map/components/flat3/floors/floor2/floor2.component';
import { Flat3Floor3Component } from './dashboard/map/components/flat3/floors/floor3/floor3.component';
import { Flat3Floor4Component } from './dashboard/map/components/flat3/floors/floor4/floor4.component';
import { Flat3Floor5Component } from './dashboard/map/components/flat3/floors/floor5/floor5.component';
import { Flat3FloorPHComponent } from './dashboard/map/components/flat3/floors/floor-ph/floor-ph.component';
import { TaracierComponent } from './dashboard/map/components/taracier/taracier.component';
import { Taracier10Component } from './dashboard/map/components/taracier10/taracier10.component';

import { UserModalComponent } from './dashboard/map/user-modal/user-modal.component';
import { Flat5Floor1Component } from './dashboard/map/components/flat5/floors/floor1/floor1.component';
import { Flat5Floor2Component } from './dashboard/map/components/flat5/floors/floor2/floor2.component';
import { Flat5Floor3Component } from './dashboard/map/components/flat5/floors/floor3/floor3.component';
import { Flat5Floor4Component } from './dashboard/map/components/flat5/floors/floor4/floor4.component';
import { Flat5Floor5Component } from './dashboard/map/components/flat5/floors/floor5/floor5.component';
import { Flat5FloorPHComponent } from './dashboard/map/components/flat5/floors/floor-ph/floor-ph.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ChartBarComponent,
    TestComponent,
    PrivacyComponent,
    FlatComponent,
    //flat3
    Flat3Component,
    Flat3Floor1Component,
    Flat3Floor2Component,
    Flat3Floor3Component,
    Flat3Floor4Component,
    Flat3Floor5Component,
    Flat3FloorPHComponent,
    //flat5
    Flat5Component,
    Flat5Floor1Component,
    Flat5Floor2Component,
    Flat5Floor3Component,
    Flat5Floor4Component,
    Flat5Floor5Component,
    Flat5FloorPHComponent,

    TaracierComponent,
    Taracier10Component,
    UserModalComponent
  ],
  imports: [

    CommonModule,
    BrowserModule,
    AppRoutingModule,
    DashboardRoutingModule,
    DashboardModule,
    LoginModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    TreeviewModule,

    // DashboardModule,
    // LoginModule,
    NgMaterialModule
  ],
  entryComponents: [
    FlatComponent,
    Flat3Component,
    Flat5Component,
    TaracierComponent,
    Taracier10Component,
    UserModalComponent
  ],
  providers: [
    LoaderService,
    AuthService,
    DealStore,
    MapStore,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      useFactory: function () {
        return new ErrorInterceptor(this.router);
      },
      multi: true
    }
  ],
  // schemas: [
  //   NO_ERRORS_SCHEMA
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
providers: [];
