import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MapModel } from 'src/app/model/map.model';
import { MapStore } from 'src/app/store/map.store';
import { MapService } from 'src/app/service/map.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'flat5-floor3',
  templateUrl: './floor3.component.html',
  styleUrls: ['./floor3.component.scss']
})
export class Flat5Floor3Component implements OnInit, AfterViewInit {

  allocatedUnits: MapModel[];

  constructor(
    private _mapStore: MapStore,
    private _mapService: MapService,
    public dialogRef: MatDialogRef<Flat5Floor3Component>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this._mapStore.set('currentMapStatsUid', `B5`);
    let allocatedUnits = this._mapStore.getByKey('allocatedUnits');
    let reservedUnits = this._mapStore.getByKey('reservedUnits');
    this.disposeMap(allocatedUnits, reservedUnits);
  }

  disposeMap(allocatedUnits: MapModel[], reservedUnits: MapModel[]) {
    this._mapService.disposeMap(allocatedUnits, reservedUnits, 'B5K3');
  }

  onClick(event: any) {

    this.dialogRef.close();
    this._mapService.onUnitClick(event);
  }

  onRightClick(event) {
    let element = event.target;
    event.preventDefault();
    let uid = element.id === "" ? element.closest("div.apt").id : element.id
    let dealId = element.getAttribute('data-dealid') === null ? element.closest("div.apt").getAttribute('data-dealid') : element.getAttribute('data-dealid');
    let userId = element.getAttribute('data-userId') === null ? element.parentElement.getAttribute('data-userId') : element.getAttribute('data-userId');
    this._mapService.openUserModal(uid, userId, dealId);
  }

}
